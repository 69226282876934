// ShimmerEffect.js
import React from "react";

const ShimmerEffectFeatured = () => {
  return (
    <div className="shimmer-wrapper-featured m-8">
      <div className="shimmer-featured"></div>
    </div>
  );
};

export default ShimmerEffectFeatured;
