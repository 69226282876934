import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import List from "./List";
import SlickSlider from "./SlickSlider";
import { fetchFeatured } from "../redux/actions/property";
import ShimmerEffectFeatured from "./ShimmerEffectFeatured";

const CACHE_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

const Featured = () => {
  const dispatch = useDispatch();
  const property = useSelector((state) => state.property.featured);
  const [cachedProperty, setCachedProperty] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    let cachedData;

    // Handle cache logic
    try {
      cachedData = JSON.parse(localStorage.getItem("featuredPropertiesCache"));
      const now = new Date().getTime();

      // Check if the cached data exists, has a valid timestamp, and is still within the expiry time
      if (cachedData && cachedData.timestamp && now - cachedData.timestamp < CACHE_EXPIRY_TIME) {
        setCachedProperty(cachedData.data);
        setLoading(false); // Set loading to false if cache is valid
        return; // Stop further execution if cache is valid
      }
    } catch (error) {
      console.error("Error reading from localStorage:", error);
    }

    // API call
    try {
      dispatch(fetchFeatured()).finally(() => setLoading(false)); // Stop loading once data is fetched
    } catch (error) {
      console.error("Error fetching featured properties:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (property?.length > 0) {
      // Cache the fetched property data when it's available in Redux
      localStorage.setItem(
        "featuredPropertiesCache",
        JSON.stringify({
          data: property,
          timestamp: new Date().getTime(),
        })
      );
      setCachedProperty(property);
    }
  }, [property]);

  const displayedProperties = cachedProperty.length > 0 ? cachedProperty : property;

  return (
    <div className="3xl:pb-8 bg-gray-100 ">
      <div className="lg:container mx-10 lg:mx-auto pt-8 pb-3 flex justify-start 3xl:max-w-[1500px]">
        <h6 className="text-navy font-Lexend sm:mx-10">
          <span className="font-bold  3xl:pl-0 4xs:text-2xl 3xl:text-3xl">Featured</span>
          <span className="4xs:text-2xl 3xl:text-3xl">Listings</span>
        </h6>
      </div>

      <div className="container mx-auto">
        <SlickSlider className="w-full">
          {loading
            ? Array(6) // Display 6 shimmer placeholders during loading
                .fill(0)
                .map((_, index) => <ShimmerEffectFeatured key={index} />)
            : displayedProperties.length > 0 &&
              displayedProperties
                .filter((p) => p.status === "Active" && p.featured === true)
                .map((prop, index) => (
                  <List key={prop.id} property={prop} />
                ))}
        </SlickSlider>
      </div>
    </div>
  );
};

export default Featured;
